import React, { memo } from "react";
import {
  View,
  StyleSheet,
  Image,
  Modal,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native";
import Text from "../../../components/Text";
import Theme from "../../../style/Theme";
import scale from "../../../utils/scale";
import { Routes } from "../../../configs";
import ButtonLinear from "../../../components/Buttons/ButtonLinear";
import HeaderButton from "../../../components/HeaderButton";
import Layout from "../../../components/Layout/Layout";
import {
  getBottomSpace,
  getStatusBarHeight,
} from "react-native-iphone-x-helper";
import InputApp from "../../../components/InputApp";
import GenderItem from "../../../components/UpdateProfile/BasicInformation/GenderItem";
import ModalSelect from "../../../components/ModalSelect";
import ButtonChangeCode from "../../../components/ButtonChangeCode";
import TextInput from "../../../components/TextInput";
import ButtonChangeCategory from "../../../components/ButtonChangeCategory";
import ModalSlideBottom from "../../../components/ModalSlideBottom";
import ModalSearchStrings from "../../../components/ModalSearchStrings";
import locales_es from "../../../locales/es";
import FullScreenLoading from "../../../components/fullscreenLoading";
import { CommonStyles } from "../../../styles/CommonStyles";

import ModalChangePhoneCode from "../../../components/SignUp/ModalChangePhoneCode";
import { useHistory } from "react-router-dom";
import ImgLoadAndCrop from "../../../components/imgLoadAndCrop";
import Form from "../../../components/form";
import iconNext from "../../../images/ic_next@3x.png";
import iconClose from "../../../images/ic_close.png";
import { Colors } from "../../../configs";
import { SOURCE_ICON } from "../../../images";
import useAddPatientPage from "../shared/useAddPatientPage";
import useDimensions from "../../../hooks/useDimensions";
import {
  InitForm,
  handleValidatorInputsTypes,
} from "../../../utils/validation/forms";
import RequireIndicator from "../../../components/RequireIndicator";
import PatientMedicalInsurancesList from "../../../components/PatientMedicalInsurances";
import { width } from "../../../configs/Const";

interface AddPatientProps {}

const AddPatientDesktop = memo((props: AddPatientProps) => {
  const history = useHistory();

  const { isDesktop } = useDimensions();

  const {
    debouncedValidateSmtpDomain,
    removeFromErrors,
    selectedDate,
    errors,
    loading,
    loadingText,
    patientData,
    screenTitle,
    profileImage,
    onSaveCrop,
    setCropping,
    cropping,
    name,
    setName,
    theme,
    lastnameRef,
    onBlur,
    dateRef,
    emailRef,
    phoneNumberRef,
    identificationRef,
    setIdentification,
    identification,
    medicalInsuranceNumberRef,
    medicalInsuranceIdNumberRef,
    setLastname,
    lastname,
    identificationTypesList,
    identificationType,
    openCountries,
    openMedicalInsuranceModal,
    openMenuOption,
    gendersList,
    setGender,
    gender,
    handleDateChange,
    codeArea,
    setPhoneNumber,
    phoneNumber,
    email,
    setEmail,
    translations,
    medicalInsuranceList,
    medicalInsurance,
    setMedicalInsurance,
    medicalInsurancePlan,
    setMedicalInsurancePlan,
    medicalInsuranceNumber,
    setMedicalInsuranceNumber,
    setMedicalInsuranceDniTitular,
    medicalInsuranceDniTitular,
    visibleCountries,
    visibleMedicalInsuranceModal,
    visibleMenuOption,
    patientId,
    closeMenuOption,
    handleSelectIdentificationTypeMenuOption,
    onChangeCode,
    countries,
    onChangeMedicalInsurance,
    closeMedicalInsuranceModal,
    maxDocLength,
    minDocLength,
    externalIdParam,
    enableButton,
    errorMessages,
    initForm,
    initValuesAdittional,
    inputRef,
    comment,
    setComment,
    medicalInsurancePatientList,
    modalMedicalInusrance,
    setModalMedicalInusrance,
    modalEditMedicalInusrance,
    setModalEditMedicalInusrance,
    setAuxIndex,
    setPatientMedicalInsuranceId,
    setMedicalInsuranceSelected,
    messageModal,
    setMessageModal,
    typeAction,
    setTypeAction,
    showInfoModal,
    setShowInfoModal,
    onAddMedicalInsurance,
    onEditMedicalInsurance,
    onRemovePatientMedicalInsurance,
    onPreSend,
    onAceptPreSend,
    showPreSend,
    setShowPreSend,
    insurancePermission,
  } = useAddPatientPage();

  const inputs = [
    {
      placeholder: locales_es.birthDate,
      onBlur: () => removeFromErrors("birthDate"),
      id: 5,
      state: "selectedDate",
      value: selectedDate,
      type: "date",
      required: false,
      wrapperCustomClassName: "",
      customClassName: errors.includes("selectedDate") && props.classNameError,
      maxDate: new window.Date().getTime(),
      minDate: new window.Date(1903, 1, 1).getTime(),
    },
  ];

  const onAddPatientMedicalInsurance = async () => {
    setMedicalInsurance(null);
    setMedicalInsurancePlan("");
    setMedicalInsuranceNumber("");
    setMedicalInsuranceDniTitular("");
    setModalMedicalInusrance(true);
  };

  const onEditPatientMedicalInsurance = async (e: any, i: any) => {
    setModalEditMedicalInusrance(true);
    const info = getMedicalInsuranceInfo(e.medical_insurance_id);
    setMedicalInsurance(info);
    setPatientMedicalInsuranceId(e.patients_medical_insurances_id);
    setAuxIndex(i);
    setMedicalInsurancePlan(e.medical_insurance_plan);
    setMedicalInsuranceNumber(e.medical_insurance_number);
    setMedicalInsuranceSelected(e.medical_insurance_selected);
    setMedicalInsuranceDniTitular(e.medical_insurance_dni_titular);
  };

  const getMedicalInsuranceInfo = (id: any) => {
    const medicalInsuranceInfo = medicalInsuranceList.find(
      (item) => item.id === id
    );
    return medicalInsuranceInfo;
  };

  const responsiveWidth = () => {
    const widthSize = window.innerWidth;

    return widthSize;
  };

  const onModalInfo = () => {
    return (
      <View style={styles.containerModalInfo}>
        <Layout
          style={{
            borderRadius: 16,
            paddingHorizontal: 24,
            paddingVertical: 32,
            width: "100%",
            maxWidth: 640,
            textAlign: "center",
            margin: "auto",
          }}
        >
          <Text center size={17} lineHeight={20} bold>
            {"Cobertura Médica"}
          </Text>
          <Text center size={13} lineHeight={22} marginTop={16}>
            {messageModal}
          </Text>

          <ButtonLinear
            white
            title={locales_es.accept}
            onPress={
              typeAction === "add"
                ? () => {
                    setModalMedicalInusrance(true);
                    setShowInfoModal(false);
                  }
                : () => {
                    setModalEditMedicalInusrance(true);
                    setShowInfoModal(false);
                  }
            }
            style={{ width: "60%", alignSelf: "center", marginTop: 15 }}
          />
        </Layout>
      </View>
    );
  };

  return (
    <>
      <View style={styles.container}>
        <View style={styles.content}>
          {loading && <FullScreenLoading text={loadingText} />}
          <View style={styles.headerWrapper}>
            <HeaderButton
              onPress={
                patientData
                  ? () =>
                      history.replace(Routes.AddPatientHub, {
                        historyBackSteps: 2,
                      })
                  : history.goBack
              }
            />
            <Text size={38} lineHeight={36} bold>
              {screenTitle || "Agregar Paciente"}
            </Text>
            <Text
              size={18}
              lineHeight={18}
              marginTop={8}
              style={{ maxWidth: "100%" }}
            >
              {locales_es.basicInformation_page.subtitle}
            </Text>
          </View>

          <View style={styles.pageContent}>
            <View style={styles.backgroundImageWrapper}>
              <Image source={SOURCE_ICON.rx} style={styles.backgroundImage} />
            </View>
            <View style={styles.formContainer}>
              <ImgLoadAndCrop
                avatarStyles={styles.avatarStyles}
                containerStyles={styles.imgContainer}
                image={profileImage}
                inputLabel={"Imagen de perfil"}
                imageId={"profileImage"}
                imageState={"profileImage"}
                inputWrapperCustomClassName="col-12 pl-md-0"
                canvasCustomClassName="profile"
                aspect={1}
                saveCrop={onSaveCrop}
                deleteCrop={() => console.log("deleteCrop")}
                deletedImage={() => console.log("deleteImage")}
                onCropping={() => setCropping(true)}
                onStopCropping={() => setCropping(false)}
              />

              {!cropping && (
                <>
                  <View style={styles.form}>
                    <View style={styles.formRow}>
                      <View style={styles.flexGrow}>
                        <InputApp
                          testID="inputName"
                          title={locales_es.fullname}
                          placeholder={locales_es.fullname}
                          value={name}
                          onChangeText={(value: string) =>
                            handleValidatorInputsTypes(
                              initForm,
                              value,
                              "letter",
                              setName,
                              "name",
                              2
                            )
                          }
                          borderColor={
                            errors.includes("name")
                              ? theme.lineErrorColor
                              : theme.innearColor
                          }
                          blurOnSubmit={true}
                          autoCapitalize="words"
                          returnKeyType="next"
                          autoFocus={false}
                          onSubmitEditing={() => {
                            lastnameRef.current.focus();
                          }}
                          showRequired={true}
                          maxLength={50}
                        />
                        {errors.includes("name") && (
                          <Text
                            size={13}
                            lineHeight={16}
                            color="red"
                            marginTop={5}
                            marginLeft={5}
                          >
                            {errorMessages.name}
                          </Text>
                        )}
                      </View>

                      <View style={styles.flexGrow}>
                        <InputApp
                          testID="inputLastname"
                          forwardRef={lastnameRef}
                          title={locales_es.lastname}
                          placeholder={locales_es.lastname}
                          marginTop={24}
                          value={lastname}
                          onChangeText={(value: string) =>
                            handleValidatorInputsTypes(
                              initForm,
                              value,
                              "letter",
                              setLastname,
                              "lastname",
                              2
                            )
                          }
                          borderColor={
                            errors.includes("lastname")
                              ? theme.lineErrorColor
                              : theme.innearColor
                          }
                          blurOnSubmit={true}
                          autoCapitalize="words"
                          returnKeyType="next"
                          autoFocus={false}
                          onSubmitEditing={() => {
                            identificationRef.current.focus();
                          }}
                          showRequired={true}
                          maxLength={50}
                        />
                        {errors.includes("lastname") && (
                          <Text
                            size={13}
                            lineHeight={16}
                            color="red"
                            marginTop={5}
                            marginLeft={5}
                          >
                            {errorMessages.lastname}
                          </Text>
                        )}
                      </View>
                    </View>

                    <View style={styles.formRow}>
                      <View style={styles.flexGrow}>
                        {identificationTypesList && identificationTypesList ? (
                          <InputApp
                            testID="inputIdentificationType"
                            title={"Tipo de Documento"}
                            marginTop={24}
                            value={identificationType}
                            editable={false}
                            onPress={openMenuOption}
                            showRequired={true}
                          />
                        ) : (
                          <ActivityIndicator
                            style={CommonStyles.activityIndicator}
                          />
                        )}
                      </View>

                      <View style={styles.flexGrow}>
                        <InputApp
                          testID="inputIdentification"
                          forwardRef={identificationRef}
                          title={locales_es.identification}
                          placeholder={locales_es.identification}
                          marginTop={24}
                          value={identification}
                          onChangeText={(value: string) =>
                            handleValidatorInputsTypes(
                              initForm,
                              value,
                              "number",
                              setIdentification,
                              "identification",
                              minDocLength
                            )
                          }
                          borderColor={
                            errors.includes("identification")
                              ? theme.lineErrorColor
                              : theme.innearColor
                          }
                          blurOnSubmit={true}
                          autoCapitalize="words"
                          returnKeyType="next"
                          autoFocus={false}
                          onSubmitEditing={() => {
                            emailRef.current.focus();
                            dateRef.current.onPressDate();
                          }}
                          showRequired={true}
                          maxLength={maxDocLength}
                        />
                        {errors.includes("identification") && (
                          <Text
                            size={13}
                            lineHeight={16}
                            color="red"
                            marginTop={5}
                            marginLeft={5}
                          >
                            {errorMessages.identification}
                          </Text>
                        )}
                      </View>
                    </View>

                    <View style={styles.formRow}>
                      <View style={styles.flexGrow}>
                        <Text size={16} lineHeight={20} marginTop={24} bold>
                          Sexo <RequireIndicator />
                        </Text>
                        <View style={styles.genders}>
                          {gendersList && gendersList.length ? (
                            gendersList.map((i, index) => {
                              const onPress = () => {
                                setGender(i);
                              };
                              return (
                                <GenderItem
                                  {...i}
                                  testID={i.name}
                                  isChoose={i.id == gender.id}
                                  isLastItem={index === gendersList.length - 1}
                                  onPress={onPress}
                                  key={i.id.toString()}
                                  stylesOverride={{ width: 100, height: 100 }}
                                />
                              );
                            })
                          ) : (
                            <ActivityIndicator
                              style={CommonStyles.activityIndicator}
                            />
                          )}
                        </View>
                      </View>
                    </View>

                    <View style={styles.formRowEdited}>
                      <View style={styles.flexGrow}>
                        <Text size={16} lineHeight={24} marginTop={24} bold>
                          Fecha de Nacimiento
                        </Text>
                        <Form
                          inputs={inputs}
                          handleDateChange={handleDateChange}
                        />
                      </View>

                      <View style={styles.flexGrow}>
                        <Text size={16} lineHeight={24} marginTop={24} bold>
                          Teléfono Móvil
                        </Text>
                        <View style={styles.phoneView}>
                          {codeArea ? (
                            <ButtonChangeCode
                              codeArea={codeArea}
                              onPress={openCountries}
                            />
                          ) : (
                            <ActivityIndicator />
                          )}
                          <TextInput
                            testID="inputPhoneNumber"
                            forwardRef={phoneNumberRef}
                            value={phoneNumber}
                            onChangeText={(value: string) =>
                              handleValidatorInputsTypes(
                                medicalInsurance &&
                                  !medicalInsurance.is_none &&
                                  medicalInsurance.name !== "Sin cobertura"
                                  ? {
                                      ...initForm,
                                      initValues: initValuesAdittional,
                                    }
                                  : initForm,
                                value,
                                "number",
                                setPhoneNumber,
                                "phoneNumber",
                                6,
                                true
                              )
                            }
                            style={[
                              styles.phoneNumber,
                              {
                                borderColor: errors.includes("phoneNumber")
                                  ? theme.lineErrorColor
                                  : theme.innearColor,
                              },
                            ]}
                            editable={true}
                            keyboardType="numeric"
                            blurOnSubmit={true}
                            returnKeyType="next"
                            autoFocus={false}
                            onSubmitEditing={() => {
                              emailRef.current.focus();
                            }}
                            placeholder="Número de teléfono"
                            maxLength={12}
                          />
                        </View>
                        <View>
                          {errors.includes("phoneNumber") && (
                            <Text
                              size={13}
                              lineHeight={16}
                              color="red"
                              marginTop={5}
                              marginLeft={5}
                            >
                              {errorMessages.phoneNumber}
                            </Text>
                          )}
                        </View>
                      </View>
                    </View>

                    <View style={styles.formRow}>
                      <View style={styles.flexGrow}>
                        <InputApp
                          testID="inputEmail"
                          forwardRef={emailRef}
                          title={locales_es.email}
                          placeholder={locales_es.email}
                          marginTop={8}
                          value={email}
                          onChangeText={(value: string) => {
                            handleValidatorInputsTypes(
                              medicalInsurance &&
                                !medicalInsurance.is_none &&
                                medicalInsurance.name !== "Sin cobertura"
                                ? {
                                    ...initForm,
                                    initValues: initValuesAdittional,
                                  }
                                : initForm,
                              value,
                              "email",
                              setEmail,
                              "email",
                              8,
                              true
                            );
                            debouncedValidateSmtpDomain(value);
                          }}
                          borderColor={
                            errors.includes("email")
                              ? theme.lineErrorColor
                              : theme.innearColor
                          }
                          keyboardType="email-address"
                          blurOnSubmit={true}
                          autoCapitalize="none"
                          returnKeyType="next"
                          autoFocus={false}
                          onSubmitEditing={() => {
                            // lastnameRef.current.focus();
                          }}
                          maxLength={75}
                        />
                        {errors.includes("email") && (
                          <Text
                            size={13}
                            lineHeight={16}
                            color="red"
                            marginTop={5}
                            marginLeft={5}
                          >
                            {errorMessages.email}
                          </Text>
                        )}
                      </View>
                    </View>

                    {insurancePermission && (
                      <View
                        style={{
                          marginTop: 28,
                          marginBottom: 28,
                        }}
                      >
                        <PatientMedicalInsurancesList
                          patientMedicalInsurances={medicalInsurancePatientList}
                          onPressAdd={onAddPatientMedicalInsurance}
                          onPressEdit={onEditPatientMedicalInsurance}
                          onPressDelete={onRemovePatientMedicalInsurance}
                          edit={false}
                        />
                      </View>
                    )}

                    <View style={styles.formRow}>
                      <View style={styles.flexGrow}>
                        <InputApp
                          testID="comment"
                          forwardRef={inputRef}
                          title={"Observaciones"}
                          placeholder={
                            "Comentarios adicionales sobre el paciente"
                          }
                          marginTop={24}
                          returnKeyType="go"
                          value={comment}
                          onChangeText={setComment}
                          showRequired={false}
                          multiline={true}
                          maxLength={250}
                          style={{
                            fontFamily: "Muli_400Regular",
                            fontSize: 15,
                            lineHeight: 18,
                            color: theme.text,
                            height: scale(30),
                            marginTop: 12,
                          }}
                        />
                      </View>
                    </View>

                    <ButtonLinear
                      testID="buttonAddPatient"
                      white
                      title={
                        externalIdParam
                          ? "Guardar en Mis Pacientes"
                          : patientId
                          ? "Guardar Cambios"
                          : "Añadir Paciente"
                      }
                      children={
                        <Image
                          source={iconNext}
                          style={styles.buttonChildren}
                        />
                      }
                      disabled={enableButton}
                      onPress={onPreSend}
                      style={styles.buttonLinear}
                    />
                  </View>
                </>
              )}

              {visibleMenuOption ? (
                <Modal
                  visible={true}
                  onRequestClose={closeMenuOption}
                  transparent
                  animationType={"fade"}
                >
                  <ModalSelect
                    onPressItem={handleSelectIdentificationTypeMenuOption}
                    choices={identificationTypesList}
                    close={closeMenuOption}
                    style={{
                      width: "55%",
                      alignSelf: "center",
                    }}
                  />
                </Modal>
              ) : null}

              {visibleCountries ? (
                <div
                  style={{
                    ...styles.modal,
                    ...(isDesktop ? styles.modalDesktop : {}),
                  }}
                >
                  <ModalChangePhoneCode
                    onChangeCode={onChangeCode}
                    countries={countries}
                  />
                </div>
              ) : null}

              {visibleMedicalInsuranceModal ? (
                <Modal
                  visible={true}
                  onRequestClose={closeMedicalInsuranceModal}
                  transparent
                  animationType={"none"}
                >
                  <ModalSlideBottom
                    onClose={closeMedicalInsuranceModal}
                    transY={0}
                    style={{ bottom: 0, width: "55%", margin: "auto" }}
                  >
                    <ModalSearchStrings
                      data={medicalInsuranceList}
                      onChangeCategory={onChangeMedicalInsurance}
                      placeholder={locales_es.search + "..."}
                    />
                  </ModalSlideBottom>
                </Modal>
              ) : null}

              {modalMedicalInusrance && (
                <Modal visible={true} transparent animationType={"fade"}>
                  <>
                    <View style={styles.modalShowPracticesContainer}>
                      <Layout
                        style={{
                          borderRadius: 16,
                          paddingHorizontal: 24,
                          paddingVertical: 32,
                          height: responsiveWidth() === 1440 ? "62%" : "75%",
                          width: "55%",
                          paddingBottom: 100,
                          alignSelf: "center",
                        }}
                      >
                        <View style={{ flexDirection: "row" }}>
                          <View style={{ width: "90%", alignSelf: "center" }}>
                            <Text
                              size={16}
                              lineHeight={24}
                              bold
                              style={{
                                alignSelf: "center",
                              }}
                            >
                              {locales_es.addMedicalInsurance}
                            </Text>
                          </View>
                          <View style={{ width: "10%" }}>
                            <TouchableOpacity
                              onPress={() => {
                                setModalMedicalInusrance(false);
                              }}
                            >
                              <Image
                                source={iconClose}
                                style={{
                                  width: 20,
                                  height: 20,
                                  alignSelf: "center",
                                }}
                              />
                            </TouchableOpacity>
                          </View>
                        </View>

                        <View style={{ marginTop: 24 }}>
                          <Text size={14} lineHeight={24} bold>
                            {locales_es.medicalInsurance}
                          </Text>
                          <ButtonChangeCategory
                            testID="buttonMedicalInsurance"
                            category={medicalInsurance}
                            onPress={openMedicalInsuranceModal}
                          />
                        </View>

                        {((medicalInsurance &&
                          !medicalInsurance.name
                            .toLowerCase()
                            .includes("ioscor") &&
                          !medicalInsurance.name
                            .toLowerCase()
                            .includes("mpn - medicamentos de primer nivel")) ||
                          !medicalInsurance) && (
                          <View style={{ marginTop: 5 }}>
                            <InputApp
                              testID="inputMedicalInsurancePlan"
                              title={
                                translations &&
                                translations.medicalInsurancePlan
                                  ? translations.medicalInsurancePlan
                                  : locales_es.medicalInsurancePlan
                              }
                              placeholder={
                                translations &&
                                translations.medicalInsurancePlan
                                  ? translations.medicalInsurancePlan
                                  : locales_es.medicalInsurancePlan
                              }
                              marginTop={16}
                              value={medicalInsurancePlan}
                              onChangeText={(value: string) =>
                                handleValidatorInputsTypes(
                                  {
                                    ...initForm,
                                    initValues: initValuesAdittional,
                                  },
                                  value,
                                  "",
                                  setMedicalInsurancePlan,
                                  "medicalInsurancePlan",
                                  0,
                                  true
                                )
                              }
                              blurOnSubmit={true}
                              autoCapitalize="words"
                              returnKeyType="next"
                              autoFocus={false}
                              onSubmitEditing={() => {
                                medicalInsuranceNumberRef.current.focus();
                              }}
                              maxLength={20}
                              showRequired={false}
                            />
                            {errors.includes("medicalInsurancePlan") && (
                              <Text
                                size={13}
                                lineHeight={16}
                                color="red"
                                marginTop={5}
                                marginLeft={5}
                              >
                                {errorMessages.medicalInsurancePlan}
                              </Text>
                            )}
                          </View>
                        )}
                        {medicalInsurance &&
                          !medicalInsurance.name
                            .toLowerCase()
                            .includes("mpn - medicamentos de primer nivel") && (
                            <View style={{ marginTop: 5 }}>
                              <InputApp
                                testID="inputMedicalInsuranceNumber"
                                forwardRef={medicalInsuranceNumberRef}
                                title={locales_es.medicalInsuranceNumber}
                                placeholder={locales_es.medicalInsuranceNumber}
                                marginTop={24}
                                returnKeyType="go"
                                value={medicalInsuranceNumber}
                                onChangeText={(value: string) =>
                                  handleValidatorInputsTypes(
                                    {
                                      ...initForm,
                                      initValues: initValuesAdittional,
                                    },
                                    value,
                                    "",
                                    setMedicalInsuranceNumber,
                                    "medicalInsuranceNumber"
                                  )
                                }
                                showRequired={true}
                                maxLength={20}
                              />
                              {errors.includes("medicalInsuranceNumber") && (
                                <Text
                                  size={13}
                                  lineHeight={16}
                                  color="red"
                                  marginTop={5}
                                  marginLeft={5}
                                >
                                  {errorMessages.medicalInsuranceNumber}
                                </Text>
                              )}
                            </View>
                          )}

                        <View>
                          {medicalInsurance &&
                            medicalInsurance.name
                              .toLowerCase()
                              .includes("ioscor") &&
                            !medicalInsurance.name
                              .toLowerCase()
                              .includes(
                                "mpn - medicamentos de primer nivel"
                              ) && (
                              <InputApp
                                testID="inputMedicalInsuranceIdNumber"
                                forwardRef={medicalInsuranceIdNumberRef}
                                title={locales_es.medicalInsuranceIdNumber}
                                placeholder={
                                  locales_es.medicalInsuranceIdNumber
                                }
                                marginTop={24}
                                returnKeyType="go"
                                value={medicalInsuranceDniTitular}
                                onChangeText={(value: string) =>
                                  handleValidatorInputsTypes(
                                    {
                                      ...initForm,
                                      initValues: initValuesAdittional,
                                    },
                                    value,
                                    "",
                                    setMedicalInsuranceDniTitular,
                                    "medicalInsuranceDniTitular"
                                  )
                                }
                                showRequired={false}
                                maxLength={20}
                              />
                            )}
                        </View>

                        <View style={{ marginTop: 24 }}>
                          <ButtonLinear
                            testID="buttonAddMedicalInsurance"
                            white
                            title={"Agregar Cobertura"}
                            children={
                              <Image
                                source={iconNext}
                                style={styles.buttonChildren}
                              />
                            }
                            disabled={
                              medicalInsurance &&
                              (medicalInsurance.name === "Sin cobertura" ||
                                medicalInsurance.name.toLowerCase() ===
                                  "mpn - medicamentos de primer nivel")
                                ? false
                                : medicalInsurance &&
                                  medicalInsurance.name !== "Sin cobertura" &&
                                  medicalInsuranceNumber !== ""
                                ? false
                                : true
                            }
                            onPress={onAddMedicalInsurance}
                            style={{
                              width: "100%",
                              justifyContend: "center",
                            }}
                          />
                        </View>
                      </Layout>
                    </View>
                  </>
                </Modal>
              )}

              {modalEditMedicalInusrance && (
                <Modal visible={true} transparent animationType={"fade"}>
                  <>
                    <View style={styles.modalShowPracticesContainer}>
                      <Layout
                        style={{
                          borderRadius: 16,
                          paddingHorizontal: 24,
                          paddingVertical: 32,
                          minHeight: "50%",
                          maxHeight: "70%",
                          overflowY: "auto",
                          width: "55%",
                          paddingBottom: 100,
                          alignSelf: "center",
                        }}
                      >
                        <View style={{ flexDirection: "row" }}>
                          <View style={{ width: "90%", alignSelf: "center" }}>
                            <Text
                              size={16}
                              lineHeight={24}
                              bold
                              style={{
                                alignSelf: "center",
                              }}
                            >
                              {locales_es.editMedicalInsurance}
                            </Text>
                          </View>
                          <View style={{ width: "10%" }}>
                            <TouchableOpacity
                              onPress={() => {
                                setModalEditMedicalInusrance(false);
                                setMedicalInsurance(null);
                                setMedicalInsurancePlan("");
                                setMedicalInsuranceNumber("");
                              }}
                            >
                              <Image
                                source={iconClose}
                                style={{
                                  width: 20,
                                  height: 20,
                                  alignSelf: "center",
                                }}
                              />
                            </TouchableOpacity>
                          </View>
                        </View>

                        <View style={{ marginTop: 24 }}>
                          <Text size={14} lineHeight={24} bold>
                            {locales_es.medicalInsurance}
                          </Text>
                          <ButtonChangeCategory
                            testID="buttonMedicalInsurance"
                            category={medicalInsurance}
                            onPress={openMedicalInsuranceModal}
                          />
                        </View>

                        {medicalInsurance &&
                          !medicalInsurance.name
                            .toLowerCase()
                            .includes("ioscor") &&
                          !medicalInsurance.name
                            .toLowerCase()
                            .includes("mpn - medicamentos de primer nivel") && (
                            <View style={{ marginTop: 5 }}>
                              <InputApp
                                testID="inputMedicalInsurancePlan"
                                title={
                                  translations &&
                                  translations.medicalInsurancePlan
                                    ? translations.medicalInsurancePlan
                                    : locales_es.medicalInsurancePlan
                                }
                                placeholder={
                                  translations &&
                                  translations.medicalInsurancePlan
                                    ? translations.medicalInsurancePlan
                                    : locales_es.medicalInsurancePlan
                                }
                                marginTop={16}
                                value={medicalInsurancePlan}
                                onChangeText={(value: string) =>
                                  handleValidatorInputsTypes(
                                    {
                                      ...initForm,
                                      initValues: initValuesAdittional,
                                    },
                                    value,
                                    "",
                                    setMedicalInsurancePlan,
                                    "medicalInsurancePlan",
                                    0,
                                    true
                                  )
                                }
                                blurOnSubmit={true}
                                autoCapitalize="words"
                                returnKeyType="next"
                                autoFocus={false}
                                onSubmitEditing={() => {
                                  medicalInsuranceNumberRef.current.focus();
                                }}
                                maxLength={20}
                                showRequired={false}
                              />
                              {errors.includes("medicalInsurancePlan") && (
                                <Text
                                  size={13}
                                  lineHeight={16}
                                  color="red"
                                  marginTop={5}
                                  marginLeft={5}
                                >
                                  {errorMessages.medicalInsurancePlan}
                                </Text>
                              )}
                            </View>
                          )}

                        {medicalInsurance &&
                          medicalInsurance.name
                            .toLowerCase()
                            .includes("ioscor") &&
                          !medicalInsurance.name
                            .toLowerCase()
                            .includes("mpn - medicamentos de primer nivel") && (
                            <View style={{ marginTop: 5 }}>
                              <InputApp
                                testID="inputMedicalInsuranceIdNumber"
                                forwardRef={medicalInsuranceIdNumberRef}
                                title={locales_es.medicalInsuranceIdNumber}
                                placeholder={
                                  locales_es.medicalInsuranceIdNumber
                                }
                                marginTop={24}
                                returnKeyType="go"
                                value={medicalInsuranceDniTitular}
                                onChangeText={(value: string) =>
                                  handleValidatorInputsTypes(
                                    {
                                      ...initForm,
                                      initValues: initValuesAdittional,
                                    },
                                    value,
                                    "",
                                    setMedicalInsuranceDniTitular,
                                    "medicalInsuranceDniTitular"
                                  )
                                }
                                showRequired={false}
                                maxLength={20}
                              />
                            </View>
                          )}
                        {medicalInsurance &&
                          !medicalInsurance.name
                            .toLowerCase()
                            .includes("mpn - medicamentos de primer nivel") && (
                            <View style={{ marginTop: 5 }}>
                              <InputApp
                                testID="inputMedicalInsuranceNumber"
                                forwardRef={medicalInsuranceNumberRef}
                                title={locales_es.medicalInsuranceNumber}
                                placeholder={locales_es.medicalInsuranceNumber}
                                marginTop={24}
                                returnKeyType="go"
                                value={medicalInsuranceNumber}
                                onChangeText={(value: string) =>
                                  handleValidatorInputsTypes(
                                    {
                                      ...initForm,
                                      initValues: initValuesAdittional,
                                    },
                                    value,
                                    "",
                                    setMedicalInsuranceNumber,
                                    "medicalInsuranceNumber"
                                  )
                                }
                                showRequired={true}
                                maxLength={20}
                              />
                              {errors.includes("medicalInsuranceNumber") && (
                                <Text
                                  size={13}
                                  lineHeight={16}
                                  color="red"
                                  marginTop={5}
                                  marginLeft={5}
                                >
                                  {errorMessages.medicalInsuranceNumber}
                                </Text>
                              )}
                            </View>
                          )}
                        <View style={{ marginTop: 24 }}>
                          <ButtonLinear
                            testID="buttonAddMedicalInsurance"
                            white
                            title={"Editar Cobertura"}
                            children={
                              <Image
                                source={iconNext}
                                style={styles.buttonChildren}
                              />
                            }
                            disabled={
                              medicalInsurance &&
                              medicalInsurance.name === "Sin cobertura"
                                ? false
                                : medicalInsurance &&
                                  medicalInsurance.name !== "Sin cobertura" &&
                                  medicalInsuranceNumber !== ""
                                ? false
                                : true
                            }
                            style={styles.buttonLinear}
                            onPress={onEditMedicalInsurance}
                          />
                        </View>
                      </Layout>
                    </View>
                  </>
                </Modal>
              )}

              {/* Modal si el medico no agrego cobertura medical al paciente */}

              {showPreSend && (
                <Modal visible={true} transparent animationType={"fade"}>
                  <>
                    <View style={styles.modalShowPracticesContainer}>
                      <Layout
                        style={{
                          borderRadius: 16,
                          paddingHorizontal: 24,
                          paddingVertical: 32,
                          height:
                            responsiveWidth() === 1440
                              ? "40%"
                              : responsiveWidth() === 1024
                              ? "45%"
                              : responsiveWidth() === 768
                              ? "45%"
                              : "40%",
                          width: responsiveWidth() === 768 ? "55%" : "45%",
                          paddingBottom: 100,
                          alignSelf: "center",
                        }}
                      >
                        <View style={{ flexDirection: "row" }}>
                          <View style={{ width: "90%", alignSelf: "center" }}>
                            <Text
                              size={25}
                              lineHeight={25}
                              bold
                              style={{
                                alignSelf: "center",
                              }}
                            >
                              Cobertura Médica
                            </Text>
                          </View>
                          <View style={{ width: "10%" }}>
                            <TouchableOpacity
                              onPress={() => {
                                setShowPreSend(false);
                              }}
                            >
                              <Image
                                source={iconClose}
                                style={{
                                  width: 20,
                                  height: 20,
                                  alignSelf: "center",
                                }}
                              />
                            </TouchableOpacity>
                          </View>
                        </View>
                        <View style={{ marginTop: 24 }}>
                          <Text
                            size={
                              responsiveWidth() === 1024
                                ? 15
                                : responsiveWidth() === 768
                                ? 14
                                : 20
                            }
                            lineHeight={24}
                            center
                          >
                            No se han registrado coberturas médicas al paciente.
                            ¿Desea guardar sus datos?
                          </Text>
                        </View>
                        <View style={{ marginTop: 24 }}>
                          <ButtonLinear
                            testID="buttonAddMedicalInsurance"
                            white
                            title={"Aceptar"}
                            disabled={false}
                            onPress={() => {
                              onAceptPreSend();
                            }}
                            style={{
                              ...styles.buttonLinear,
                              width: "80%",
                              alignSelf: "center",
                            }}
                          />
                        </View>
                        <View style={{ marginTop: 5 }}>
                          <ButtonLinear
                            testID="buttonAddMedicalInsurance"
                            white
                            title={"Cancelar"}
                            disabled={false}
                            onPress={() => {
                              setShowPreSend(false);
                            }}
                            style={{
                              ...styles.buttonLinear,
                              width: "80%",
                              alignSelf: "center",
                            }}
                            colors={[Colors.Tomato, Colors.Tomato]}
                          />
                        </View>
                      </Layout>
                    </View>
                  </>
                </Modal>
              )}

              {showInfoModal && (
                <Modal visible={true} transparent animationType={"fade"}>
                  {onModalInfo()}
                </Modal>
              )}
            </View>
          </View>
        </View>
      </View>
    </>
  );
});

export default AddPatientDesktop;

const styles = StyleSheet.create({
  header: {
    ...Theme.flexRowSpace,
  },
  container: {
    ...Theme.flexOne,
  },
  contentContainerStyle: {
    // paddingHorizontal: 24,
    // paddingTop: 40,
    paddingBottom: getBottomSpace() + 160,
  },
  buttonChildren: {
    ...Theme.icons,
    marginLeft: 8,
  },
  inputApp: {
    marginTop: 24,
  },
  name: {
    marginTop: scale(38),
  },
  homeAddress: {
    marginTop: 32,
  },
  buttonLinear: {
    // marginTop: 52,
  },
  bottomFixedWrapper: {
    position: "absolute",
    bottom: 0,
    zIndex: 100,
    width: "100%",
    paddingHorizontal: 24,
    paddingBottom: 52,
    backgroundColor: "#fff",
  },
  genders: {
    marginTop: 24,
    ...Theme.flexRow,
  },
  phoneView: {
    ...Theme.flexRow,
    marginTop: 4,
  },
  phoneNumber: {
    marginLeft: 8,
    flex: 1,
  },

  content: {
    minHeight: "100%",
    paddingBottom: 24,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: getStatusBarHeight(),
  },
  headerWrapper: {
    flexDirection: "column",
    alignItems: "flex-start",
    maxWidth: "100%",
    marginTop: 32,
  },
  imgContainer: { minWidth: 380, width: "70%", marginTop: 0 },
  avatarStyles: {
    width: 150,
    height: 150,
    maxWidth: 150,
    maxHeight: 150,
    marginRight: 20,
  },
  pageContent: {
    flexDirection: "row-reverse",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    marginTop: 32,
  },
  backgroundImageWrapper: {
    width: "40%",
    minWidth: 400,
    alignItems: "flex-start",
    marginBottom: 32,
  },
  backgroundImage: { width: 420, height: 380 },
  formContainer: { flex: 1, minWidth: 400 },
  form: { marginTop: 24 },
  prefixField: { width: 200 },
  prefixSelector: {
    padding: 0,
    marginTop: 0,
  },
  formRow: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "baseline",
    gap: 16,
  },
  formRowEdited: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "baseline",
    gap: 16,
    zIndex: 12000,
  },
  cta: { width: 200 },
  flexGrow: { flex: 1 },
  modal: {
    position: "absolute",
    backgroundColor: "rgba(0,0,0,.5)",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: "flex",
  },
  modalDesktop: {
    position: "fixed",
  },
  modalShowPracticesContainer: {
    backgroundColor: Colors.Black68,
    ...StyleSheet.absoluteFillObject,
    paddingHorizontal: 24,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    justifyContent: "center",
  },
  containerModalInfo: {
    backgroundColor: Colors.Black68,
    ...StyleSheet.absoluteFillObject,
    paddingHorizontal: 24,
    justifyContent: "center",
    zIndex: 1000,
  },
});
