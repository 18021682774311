import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { showModalInfo } from '../../../modules/ViewHelpers';
import { api, helpers } from "../../../import";
import {Colors, Routes} from '../../../configs';
import AuthService from '../../../modules/authService';
import { set } from 'react-native-reanimated';

interface RouteParams {
    code?: string;

}

const useVerificateCodeEmail = () => {
    const auth = new AuthService();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const {code}:RouteParams = useParams<RouteParams>();
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const [route, setRoute] = useState(``);

    
    const emailSuccess = (res: any) => {
        auth    
         .saveUserData(res.data);
        auth
         .setHeaderInOrderOfUserStatus();
        setLoading(false);
        
        setTimeout(() => {
            if(!res.data.user.name || !res.data.user.lastname || !res.data.user.professional_prefix_id){
                history.replace(`/${Routes.SignUpSuccessful}`, {index: 0})
            } else {
                history.replace(`/${Routes.Home}`)

            }
        }, 5000);

    }
    const validatorData = (res: any) =>{
        if(!res.name || !res.lastname || !res.professional_prefix_id){
            setRoute(`/${Routes.SignUpSuccessful}`)
        } else {
            setRoute(`/${Routes.Home}`)

        }
            
    }

    const onPressContinue = () => {
        if (loading == true) {
            return;
        }
        
        history.replace(route);
    }


    useEffect(() => {

        setLoading(true);
        api
         .emailValidation(code)
            .then((res: any) => {
                validatorData(res.data.user);
                emailSuccess(res);
                setMessage(res.message);
                setSuccess(true);
            })
            .catch((err) => {
                showModalInfo('Error', helpers.getErrorMsg(err));
                setLoading(false);
                history.push('/login');
            })
    }, []);
    


    return {
        loading,
        success,
        message,
        onPressContinue
    }
}
 
export default useVerificateCodeEmail;