import React from 'react';
import {
  View,
  StyleSheet,
  Image,
  TouchableOpacity,
  Platform,
  Keyboard,
  Modal
} from 'react-native';
import Layout from "../../components/Layout/Layout";
import Text from '../../components/Text';
import {Colors, Routes} from '../../configs';
import InputApp from '../../components/InputApp';
import FullScreenLoading from '../../components/fullscreenLoading';
import ButtonLinear from '../../components/Buttons/ButtonLinear';
import {useHistory} from "react-router-dom";
import {useTheme} from '../../configs/Theme';
// @ts-ignore
import locales_es from '../../locales/es';
import AuthService from '../../modules/authService';
import Helpers from '../../modules/helpers';
import {validateEmptyFields, handleValidatorInputsTypes,InitForm} from '../../utils/validation/forms';
import eyeOnIcon from '../../images/ic_eye_on.png';
import Footer from "../../components/footer";
import { showModalAction } from '../../modules/ViewHelpers';
import Theme from '../../style/Theme';
import { SOURCE_ICON } from "../../images"
import RequireData from '../../components/RequireData';
import useDimensions from '../../hooks/useDimensions';
import useSendValidateEmail from './useSendValidateEmail';

interface SendValidateEmailProps {
    emailToValidate?: string;
    register?: boolean;

}

const SendValidateEmail = ({emailToValidate, register}: SendValidateEmailProps) => {
    const history = useHistory();
    const {theme} = useTheme();
    const {
        errors,
        email,
        setEmail,
        setErrors,
        confirmEmail,
        errorMessages,
        setErrorMessages,
        enableButton,
        setEnableButton,
        initForm,
        setConfirmEmail,
        maxLengthEmail,
        onBlurEmail,
        emailRef,
        isValidEmail,
        counter,
        setCounter,
        isCounterActive,
        setIsCounterActive,
        formatSeconds,
        resendEmailVerification,
        goBack
     } = useSendValidateEmail({emailToValidate, register});

    return ( 
        <div
      testID="loginScreen"
      className="w-100 p-3 p-md-0"
      style={{flex: 1, backgroundColor:  Colors.Snow }}>

      <div className="row h-100">
        <div className="d-none d-md-inline-flex col-6">
          <div className="rcta-bg-logo"/>
        </div>
        <div className="col-12 col-md-6 align-self-center " style={{... styles.desktopContent, gap:30}}>
          <TouchableOpacity onPress={() => goBack()}>
            <Image
              source={SOURCE_ICON.back}
              style={{height: 30, width: 30, cursor: 'pointer', marginBottom: 30, marginRigth: 10}}
              resizeMode="contain"
              
            />
          </TouchableOpacity>
          <Text center bold size={30}>
              Validación de tu e-mail
          </Text>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <Text  lineHeight={15}left size={16}>
              {locales_es.login_page.email_validation}
          </Text>
          <InputApp
                editable={!register}
                testID="email"
                borderColor={
                  errors.includes('email')
                    ? theme.lineErrorColor
                    : theme.innearColor
                }
                title={locales_es.email}
                placeholder={locales_es.email}
                value={email}
                onChangeText={(value:string)=>{handleValidatorInputsTypes(initForm, value, 'email',setEmail,'email',8); maxLengthEmail(value)}}
                onBlur={() => {
                  onBlurEmail('email',email);
                  emailRef.current.focus();
                }}
                icon={
                  <Image
                    source={require('../../images/ic_accept.png')}
                    style={styles.icon}
                  />
                }
                isShowIcon={isValidEmail}
                keyboardType="email-address"
                blurOnSubmit={true}
                onSubmitEditing={() => {
                  // this.secondTextInput.focus();
                  //  alert(JSON.stringify(passwordRef));
                  emailRef.current.focus();
                }}
                autoCapitalize="none"
                returnKeyType="next"
                autoFocus={false}
                marginTop={24}
                showRequired={true}
                maxLength={75}
              />
              {
                errors.includes('email') && (
                  <Text size={13} lineHeight={16} marginTop={4} color={Colors.Red}>{errorMessages.email}</Text>
                )
              }
              <br/>
              <br/>
              <View style={{justifyContent: 'center', alignText: 'center', alignItems: 'center'}}>
                <Text center lineHeight={15} bold size={16}>
                  ¿No recibiste el e-mail?
                </Text>
                <br/>
                <Text center lineHeight={15} size={16}>
                  Pódras reenviar el e-mail de validación en {formatSeconds(counter)}
                </Text>
              </View>
              <br/>
              <ButtonLinear
                testID="login-submit"
                white
                title={'Reenviar e-mail'}
                onPress={resendEmailVerification}
                style={{marginTop: 24}}
                disabled={isCounterActive || errors.includes('email') || email === ''}
              />
        </div>
        
      </div>
    </div>
  );
     
}
 
export default SendValidateEmail;


const styles = StyleSheet.create({
    icon: {
      width: 24,
      height: 24,
    },
    desktopContent: {
      margin: 'auto',
      maxWidth: 550,
      gap: 4,
    },
    desktopForgot: {
      fontSize: 18
    },
  });
  