import React from "react";
import FullScreenLoading from "../../components/fullscreenLoading";
import useVerificateCodeEmail from "./shared/useVerificateCodeEmail";
import { StyleSheet, Image, View } from "react-native";
import Text from "../../components/Text";
import { width } from "../../configs/Const";
import { showModalInfo } from "../../modules/ViewHelpers";
import iconSuccess from "../../images/img_success_1@3x.png";
import scale from "../../utils/scale";
import ButtonLinear from "../../components/Buttons/ButtonLinear";
import { Colors } from "../../configs";

const VerificateCodeEmail = () => {
  const { loading, success, message, onPressContinue } =
    useVerificateCodeEmail();

  return (
    <>
      <div
        className="w-100 p-3 p-md-0"
        style={{ flex: 1, backgroundColor: Colors.Snow }}
      >
        <div className="row h-100">
          <div className="d-none d-md-inline-flex col-6">
            <div className="rcta-bg-logo" />
          </div>
          {loading && !success && <FullScreenLoading />}
          {!loading && success && (
            <div
              className="col-12 col-md-6 align-self-center text-center "
              style={{ ...styles.desktopContent }}
            >
              <View style={{ justifyContent: "center" }}>
                <Image source={iconSuccess} style={styles.successImage} />
              </View>
              <br />
              <br />
              <View>
                <Text center size={20} marginBottom={6} lineHeight={24} bold>
                  ¡Listo! validamos tu e-mail correctamente
                </Text>
                <br />
                <Text center size={16} marginBottom={10} lineHeight={24}>
                  Continua para completar tu perfil y configuración de receta
                  para comenzar a usar RCTA
                </Text>
              </View>
              <br />
              <br />
              <br />
              <br />
              <ButtonLinear
                white
                title={"Continuar"}
                onPress={() => {
                  onPressContinue();
                }}
                colors={["#1069E5", "#1069E5"]}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    width: "100wh",
    height: "100vh",
    padding: "32px",
  },
  successImage: {
    width: scale(122, true),
    height: scale(122, true),
    alignSelf: "center",
  },
  buttonLinear: {
    width: "32vw",
    marginTop: 32,
  },
  desktopContent: {
    margin: "auto",
    maxWidth: 550,
    gap: 4,
  },
});

export default VerificateCodeEmail;
