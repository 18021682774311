import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { Colors } from "../../../configs";
import locales_es from "../../../locales/es";
import Footer from "../../../components/footer";
import SignUpForm from "../shared/SignUpForm";
import { useParams } from "react-router-dom";
import SendValidateEmail from "../../SendValidateEmail/SendValidateEmail";

interface SignUpProps {}

const SignUpDesktop = (props: SignUpProps) => {
  let { country } = useParams();
  const [overlay, setOverlay] = useState(false);
  const [email, setEmail] = useState("");

  return (
    <>
      {!overlay && (
        <div
          className="w-100 p-3 p-md-0"
          style={{ flex: 1, backgroundColor: Colors.Snow }}
        >
          <div className="row h-100">
            <div className="d-none d-md-inline-flex col-6">
              <div className="rcta-bg-logo" />
            </div>
            <div className="col-12 col-md-6 align-self-center text-center">
              <div style={styles.content}>
                <SignUpForm
                  countryIn={country}
                  setShareData={setEmail}
                  setOverlay={setOverlay}
                />
              </div>
            </div>
          </div>
          <Footer className="app-footer-auth" />
        </div>
      )}
      {overlay && <SendValidateEmail register={true} emailToValidate={email} />}
    </>
  );
};

export default SignUpDesktop;

const styles = StyleSheet.create({
  title: {
    fontSize: 40,
    fontWeight: "bold",
    textAlign: "left",
    paddingLeft: "1rem",
  },
  subTitle: {
    fontSize: 20,
    textAlign: "left",
    padding: "1rem",
  },
  content: {
    margin: "auto",
    maxWidth: 600,
    paddingTop: 24,
    overflow: "scroll",
    height: "100vh",
  },
});
